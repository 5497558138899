@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@200;400;700&display=swap");

// user page fonts
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&family=Merriweather:wght@400;700&family=Open+Sans:ital,wght@0,400;1,700&family=PT+Sans:wght@400;700&family=Raleway:wght@400;700&family=Roboto:wght@400;700&family=Trispace:wght@400;700&display=swap");

body {
  margin: 0;
  background-color: #f5f6fa;
  overflow-x: hidden;
  width: 100%;
}

* {
  font-family: Sarabun, Arial;
  box-sizing: border-box;
  user-select: none;
}
